// extracted by mini-css-extract-plugin
export var chevronExpanded = "FooterNavigationSection__chevronExpanded__A7MFo";
export var column = "FooterNavigationSection__column__KCcy2";
export var contentColumn = "FooterNavigationSection__contentColumn__kSfJ5";
export var flex = "FooterNavigationSection__flex__vOkH5";
export var flexColumn = "FooterNavigationSection__flexColumn__Wze_C";
export var gap1 = "FooterNavigationSection__gap1__zpkVC";
export var gap2 = "FooterNavigationSection__gap2__HhhwC";
export var gap3 = "FooterNavigationSection__gap3__aSmjv";
export var gap4 = "FooterNavigationSection__gap4__ttjqz";
export var gap5 = "FooterNavigationSection__gap5__Pwi1r";
export var header = "FooterNavigationSection__header__UJUHz";
export var headerWrapper = "FooterNavigationSection__headerWrapper__zuMad";
export var link = "FooterNavigationSection__link__JldMn";
export var linksWrapper = "FooterNavigationSection__linksWrapper__kioXz";
export var linksWrapperCollapsed = "FooterNavigationSection__linksWrapperCollapsed__Q4NG4";
export var row = "FooterNavigationSection__row__q2ajs";